// UserProvider.js
import React, { useState } from "react";
import RoutersContext from "./RoutersContext";

export const RoutersProvider = ({ children }) => {
  const [routers, setRouters] = useState(null);

  // Context value now includes the function to fetch user data
  const contextValue = {
    routers,
    setRouters,
  };

  return (
    <RoutersContext.Provider value={contextValue}>
      {children}
    </RoutersContext.Provider>
  );
};
