// UserProvider.js
import React, { useState } from "react";
import SubjectsContext from "./SubjectsContext";

export const SubjectsProvider = ({ children }) => {
  const [subjects, setSubjects] = useState(null);

  // Context value now includes the function to fetch user data
  const contextValue = {
    subjects,
    setSubjects,
  };

  return (
    <SubjectsContext.Provider value={contextValue}>
      {children}
    </SubjectsContext.Provider>
  );
};
