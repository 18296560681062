import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import { Button, Alert } from "react-bootstrap";
// import { Howl, Howler } from "howler";
import Loading from "../Components/Loading";

const DataShare = lazy(() => import("../Components/PublicShare/DataShare"));
function ShareRouteComponent() {
  return (
    <Routes>
      <Route
        path="/data-share/:shareId"
        element={
          <Suspense fallback={<Loading />}>
            <DataShare />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default ShareRouteComponent;
