import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "./Contexts/UserContext/UserProvider";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0Provider
      domain="login.4sightlabs.com"
      clientId="uxiPXPArqbkTkPMnJ5AGWkZAskgcmEMw"
      useRefreshTokens={true}
      // cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        // scope: "openid profile email offline_access",
        audience: "https://login.4sightlabs.com/api/v2/",
      }}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </Auth0Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
