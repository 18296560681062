import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Loading() {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center min-vh-100"
    >
      <Row className="align-items-center">
        <Col className="text-center align-items-center">
          {/* Replace the GIF with a WebM video */}
          <video
            autoPlay
            loop
            muted
            playsInline
            // preload="metadata"
            poster="/svgs/Navbar/4SightLabs Logo.svg"
            // width={300}
            alt="loading"
            style={{
              width: "250px",
              aspectRatio: "16/9",
            }}
          >
            <source src="/gifs/Loading.webm" type="video/webm" />
          </video>

          {/* Optionally, if you have a logo based on the theme */}
          {/* <img
            src={
              userData?.userInfo?.darkTheme
                ? "/svgs/4Sight_Labs_Logo_White.svg"
                : "/svgs/4Sight_Labs_Logo_Black.svg"
            }
            alt="Loading..."
            style={{ width: "300px" }}
          /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default Loading;
