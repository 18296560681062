import React, { lazy, Suspense } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Loading from "../Components/Loading";

const NoAccess = lazy(() => import("../Components/NoAccess"));

function NoAccessRouteComponent() {
  return (
    <Routes>
      <Route
        path="/noaccess"
        element={
          <Suspense fallback={<Loading />}>
            <NoAccess />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/noaccess" replace />} />
    </Routes>
  );
}

export default NoAccessRouteComponent;
