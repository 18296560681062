import React, { lazy, Suspense } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Loading from "../Components/Loading";

const PrivacyPolicy = lazy(() =>
  import("../Components/PrivacyPolicy/PrivacyPolicy")
);

function PrivacyPolicyRouteComponent() {
  return (
    <Routes>
      <Route
        path="/privacypolicy"
        element={
          <Suspense fallback={<Loading />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/privacypolicy" replace />} />
    </Routes>
  );
}

export default PrivacyPolicyRouteComponent;
